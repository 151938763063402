import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BreadcrumbService, Breadcrumb } from 'angular-crumbs';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { CookieService } from './services/cookies.service';
import { ToastNotificationInitializer, DialogLayoutDisplay, ToastPositionEnum, AppearanceAnimation, DisappearanceAnimation, ToastUserViewTypeEnum } from '@costlydeveloper/ngx-awesome-popup';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VisiteurService } from './visiteur.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})
export class AppComponent implements OnInit {


  // USER = 'TEST USER';

    userIP = '';

  constructor(private titleService: Title,
              // private cookieService: CookieService,
              private breadcrumbService: BreadcrumbService,
              private cookieSnackBar: MatSnackBar,
              private visiteurservice: VisiteurService,
              private httpClient: HttpClient) {
  }
  ngOnInit(): void {
    this.breadcrumbService.breadcrumbChanged.subscribe(crumbs => {
      this.titleService.setTitle(this.createTitle(crumbs));
    });
    // this.cookieBanner();

    //this.visiteurservice.incrementPageCount().then(data => this.visiteurservice.changeCount(data));

    this.loadIp();
  }


    loadIp() {
      this.httpClient.get('https://jsonip.com').subscribe(
        (value:any) => {
          console.log(value);
          this.userIP = value.ip;
          this.visiteurservice.addVisiteur(this.userIP).subscribe((res)=>{
                //this.router.navigate(['/menus']);
              },(err)=>{
                console.log(err)
              })
        },
        (error) => {
          console.log(error);
        }
      );
    }


  onActivate(event){
    window.scroll(0,0);
  }
  private createTitle(routesCollection: Breadcrumb[]) {
    const title = 'GNA - ASSURANCE';
    const titles = routesCollection.filter((route) => route.displayName);

    if (!titles.length) { return title; }

    const routeTitle = this.titlesToString(titles);
    return `${title}${routeTitle}`;
  }

  private titlesToString(titles) {
    return titles.reduce((prev, curr) => {
      return `${prev} | ${curr.displayName}`;
    }, '');
  }



  // Create the method
  // cookieBanner() {
  //   const newCookieBanner = new ToastNotificationInitializer();

  //   newCookieBanner.setTitle('Do you like cookies? 🍪');
  //   newCookieBanner.setMessage(
  //     'We use cookies to ensure you get the best experience on our website.'
  //   );

  //   // Choose layout color type
  //   newCookieBanner.setConfig({
  //     textPosition: 'right', // optional
  //     buttonPosition: 'right', // optional
  //     layoutType: DialogLayoutDisplay.NONE, // SUCCESS | INFO | NONE | DANGER | WARNING
  //     toastPosition: ToastPositionEnum.BOTTOM_LEFT, // TOP_LEFT | TOP_CENTER | TOP_RIGHT | TOP_FULL_WIDTH | BOTTOM_LEFT | BOTTOM_CENTER | BOTTOM_RIGHT | BOTTOM_FULL_WIDTH
  //     animationIn: AppearanceAnimation.SLIDE_IN_LEFT,
  //     toastUserViewType: ToastUserViewTypeEnum.STANDARD,
  //     animationOut: DisappearanceAnimation.SLIDE_OUT_LEFT,
  //     customStyles: {
  //       buttonCSS: 'margin: 3px;',

  //     }
  //   });

  //   newCookieBanner.setButtonLabels('Accepter', 'Refuser');

  //   // Simply open the popup and observe button click
  //   newCookieBanner.openToastNotification$().subscribe(resp => {
  //     if (resp.clickedButtonID) {
  //       console.log('Button clicked: ', resp.clickedButtonID);
  //       if (resp.success) {
  //         // user has accepted
  //         // do your logic here
  //         this.cookieService.setCookie({
  //           name: this.USER,
  //           value: 'HamBer_Stack',
  //           session: true,
  //         });
  //       } else {
  //         // user has declined
  //         // do your logic here
  //         this.cookieService.deleteCookie(this.USER);
  //       }
  //     }
  //   });
  // }

}
