import { Injectable } from '@angular/core';
import {GlobalVariable} from './globals';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {VisiteModel} from './visite.model';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class VisiteurService {

  apiurl = GlobalVariable.BASE_API_URL + "api/visiteuser";

  constructor(private http:HttpClient) { }

    /*private _pageCount = new BehaviorSubject<number>(0);
    count$ = this._pageCount.asObservable();

    incrementPageCount(){
        const pageCount = this.af.object('/pageCount/').$ref
          .ref.transaction(count => {
            return count + 1;
          }).then((data) => {return data.snapshot.node_.value_;});

        return pageCount;
    }*/

    /*addVisiteur(visite:VisiteModel): Observable<any>{
        const headers = {'content-type':'application/json'};
        const body = JSON.stringify(visite);
        console.log(body);
        return this.http.post(this.apiurl ,body,{'headers':headers} );
    }*/

    addVisiteur(ip_adresse: string){

        const headers = {'content-type':'application/json'};

        const data = {
          ip_adresse: ip_adresse
        }

        return this.http.post(this.apiurl, data, {headers: headers});
    }
}
