import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // home
  { path: '', loadChildren: () => import('./components/pages/home-v3/home-v3.module').then(m => m.HomeV3Module), data: { breadcrumb: 'ACCUEIL' } },
  // About
  { path: 'metier', loadChildren: () => import('./components/pages/about/about.module').then(m => m.AboutModule), data: { breadcrumb: 'A propos de nous' } },
  // Services
  { path: 'particulier', loadChildren: () => import('./components/pages/servicesp/servicesp.module').then(m => m.ServicespModule), data: { breadcrumb: 'PARTICULIERS' } },
  { path: 'entreprise', loadChildren: () => import('./components/pages/services/services.module').then(m => m.ServicesModule), data: { breadcrumb: 'ENTREPRISES' } },

  { path: 'services', loadChildren: () => import('./components/pages/services/services.module').then(m => m.ServicesModule), data: { breadcrumb: 'Nos produits d\'assurances' } },
 // { path: 'service-details', loadChildren: () => import('./components/pages/service-details/service-details.module').then(m => m.ServiceDetailsModule), data: { breadcrumb: 'Service Details' } },
  // Pages
  { path: 'mentionslegales', loadChildren: () => import('./components/pages/case-details/case-details.module').then(m => m.CaseDetailsModule), data: { breadcrumb: 'Mentions legales' } },
  { path: 'politiqueconfidentialite', loadChildren: () => import('./components/pages/case-details1/case-details1.module').then(m => m.CaseDetails1Module), data: { breadcrumb: 'Politique de confidentialite' } },

  { path: 'PlanSite', loadChildren: () => import('./components/pages/plan-site/plan-site.module').then(m => m.PlanSiteModule), data: { breadcrumb: 'Plan du site' } },



  { path: 'blog/cat/:catId', loadChildren: () => import('./components/pages/blog-grid/blog-grid.module').then(m => m.BlogGridModule), data: { breadcrumb: 'Blog Grid' } },
  { path: 'blog/tag/:tagId', loadChildren: () => import('./components/pages/blog-grid/blog-grid.module').then(m => m.BlogGridModule), data: { breadcrumb: 'Blog Grid' } },
  { path: 'blog/author/:authorId', loadChildren: () => import('./components/pages/blog-grid/blog-grid.module').then(m => m.BlogGridModule), data: { breadcrumb: 'Blog Grid' } },

  { path: 'actualite', loadChildren: () => import('./components/pages/blog-grid/blog-grid.module').then(m => m.BlogGridModule), data: { breadcrumb: 'ACTUALITES' } },
  { path: 'actualite-details/:id', loadChildren: () => import('./components/pages/blog-details/blog-details.module').then(m => m.BlogDetailsModule), data: { breadcrumb: 'Actualite Details' } },
  // Contact
  { path: 'contact', loadChildren: () => import('./components/pages/contact/contact.module').then(m => m.ContactModule), data: { breadcrumb: 'Contacts' } },
  //actualite
  { path: 'actualites', loadChildren: () => import('./components/pages/actualite/actualite.module').then(m => m.ActualiteModule), data: { breadcrumb: 'Actualite' } },
  //metier
  { path: 'metiers', loadChildren: () => import('./components/pages/metier/metier.module').then(m => m.MetierModule), data: { breadcrumb: 'A propos de nous' } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
