import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbModule } from 'angular-crumbs';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './preloader/preloader.component';
import {HttpClientModule} from '@angular/common/http';

import {TooltipModule} from 'ng2-tooltip-directive';

import { CountoModule } from 'angular2-counto';
import { SafePipe } from './safe.pipe';

import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { CommonModule } from '@angular/common';
import { NgxAwesomePopupModule, ToastNotificationConfigModule } from '@costlydeveloper/ngx-awesome-popup';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { toastCoreConfig } from '@costlydeveloper/ngx-awesome-popup/ngx-awesome-popup/types/toast-notification/core/classes';


@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    SafePipe
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BreadcrumbModule,
    NgbModule,
    SlickCarouselModule,
    HttpClientModule,
    CountoModule,
    TooltipModule,
    AutocompleteLibModule,
    MatSnackBarModule,
    NgxAwesomePopupModule.forRoot({
      colorList: {
             primary  : '#ff9e00', // optional
             secondary: '#989ea5', // optional
             info     : '#2f8ee5', // optional
             success  : '#3caea3', // optional
             warning  : '#ffc107', // optional
             danger   : '#e46464', // optional
             light    : '#fbfbfb', // optional
             dark     : '#343a40', // optional
             customOne: '#34fa40', // optional
             customTwo: '#343f40'  // optional (up to custom five)
            }
  }), 
    ToastNotificationConfigModule.forRoot({
        globalSettings: {
          allowedNotificationsAtOnce: 1,
       }
    }
    )

  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
